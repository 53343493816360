<template>
  <div class="container-fluid">
    <Breadcrumb />
    <el-dialog
      title="接收邀請信和更新薪水的老師"
      :visible="sendInfoDialogVisible"
      @close="sendInfoDialogVisible = false"
      width="600px"
    >
      <el-table :data="invitedAndNeedToUpdateSalaryTeachers">
        <el-table-column label="Teacher" width="160">
          <template slot-scope="scope">
            <router-link
              :to="{ name: 'ProfileForAdmin', params: { id: scope.row.id } }"
            >
              {{
                userMethods.displayName(
                  scope.row.first_name,
                  scope.row.last_name,
                  scope.row.chinese_name
                )
              }}
            </router-link>
          </template>
        </el-table-column>
        <el-table-column label="Salary" width="160">
          <template slot-scope="scope">
            {{
              addCurrencySymbol(
                sessionClass.is_online
                  ? scope.row.online_salary
                  : scope.row.salary,
                sessionClass.is_online
                  ? scope.row.online_salary_currency
                  : scope.row.currency
              )
            }}
          </template>
        </el-table-column>
        <el-table-column label="寄送邀請" width="100">
          <template slot-scope="scope">
            <span
              v-if="scope.row.invitedTeacher"
              class="action-icon-without-pointer checkIcon"
            >
              <i class="fas fa-check" />
            </span>
          </template>
        </el-table-column>
        <el-table-column label="更新薪水" width="100">
          <template slot-scope="scope">
            <span
              v-if="scope.row.needToUpdateSalaryTeacher"
              class="action-icon-without-pointer checkIcon"
            >
              <i class="fas fa-check" />
            </span>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="sendInfoDialogVisible = false">
          {{ $t("button.cancel") }}
        </el-button>
        <el-button
          type="primary"
          @click="sendInvitationToTeacherAndUpdateSalary"
        >
          {{ $t("button.confirm") }}
        </el-button>
      </span>
    </el-dialog>
    <h3>{{ $t("pageTitle.privateLessonInvitation") }}</h3>
    <hr class="separate-line" />
    <div class="row">
      <div class="col-md-12">
        <el-card class="box-card">
          <div class="card-body">
            <h5 class="card-title">
              {{ helper.displayI18nText($i18n.locale, courseSession.title) }}
            </h5>
            <p class="card-text">
              Student:
              <span
                :key="studentClassTicket.id"
                v-for="(studentClassTicket, index) in studentClassTickets"
              >
                <router-link
                  :to="{
                    name: 'ProfileForAdmin',
                    params: { id: studentClassTicket.student.id }
                  }"
                >
                  {{
                    userMethods.displayName(
                      studentClassTicket.student.first_name,
                      studentClassTicket.student.last_name,
                      studentClassTicket.student.chinese_name
                    )
                  }}<span v-if="index !== studentClassTickets.length - 1"
                    >,</span
                  >
                </router-link>
              </span>
            </p>
            <p class="card-text">Date: {{ this.sessionClass.started_on }}</p>
          </div>
        </el-card>
      </div>
    </div>
    <br />
    <div class="row">
      <div
        class="col-md-12"
        v-if="classTeacherInvitations && classTeacherInvitations.length > 0"
      >
        <p>Teachers:</p>
        <table class="table table-sm  table-bordered">
          <thead>
            <tr>
              <th scope="col" width="50px">#</th>
              <th scope="col" width="150px">Teacher</th>
              <th scope="col" width="110px">No Reply</th>
              <th scope="col" width="110px">Accepted</th>
              <th scope="col" width="110px">Rejected</th>
              <th scope="col">Availability</th>
              <th scope="col" width="150px">Salary</th>
              <th scope="col" width="150px">Date</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(classTeacherInvitation, idx) in classTeacherInvitations"
              :key="classTeacherInvitation.id"
            >
              <th scope="row">{{ idx + 1 }}</th>
              <td>
                <router-link
                  :to="{
                    name: 'ProfileForAdmin',
                    params: { id: classTeacherInvitation.teacher_user_id }
                  }"
                >
                  {{
                    userMethods.displayName(
                      classTeacherInvitation.teacher_user.first_name,
                      classTeacherInvitation.teacher_user.last_name
                    )
                  }}
                </router-link>
              </td>
              <td>
                <i
                  v-if="classTeacherInvitation.replied_status == 0"
                  style="color:grey;"
                  class="el-icon-question"
                ></i>
              </td>
              <td>
                <i
                  v-if="classTeacherInvitation.replied_status == 1"
                  class="fa fa-check-circle ivyway-green"
                ></i>
              </td>
              <td>
                <i
                  v-if="classTeacherInvitation.replied_status == 2"
                  style="color:indianred;"
                  class="el-icon-error"
                />
              </td>
              <td>
                {{ classTeacherInvitation.replied_note }}
              </td>
              <td>
                {{
                  addCurrencySymbol(
                    sessionClass.is_online
                      ? classTeacherInvitation.online_salary
                      : classTeacherInvitation.salary,
                    sessionClass.is_online
                      ? classTeacherInvitation.online_salary_currency
                      : classTeacherInvitation.currency
                  )
                }}
                / hr
              </td>
              <td>
                {{
                  instant.formatGoogleTime(classTeacherInvitation.created_at)
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <hr />

    <el-alert type="warning" :closable="false" show-icon class="mb-3">
      <div style="font-size: 13px;">
        內容用英文填寫，會發給老師的。
      </div>
    </el-alert>
    <el-form
      @submit.prevent.native="submit"
      :validate-on-rule-change="false"
      label-position="right"
    >
      <el-form-item
        label="上課需求"
        :rules="[commonRules.input]"
        :prop="sessionClass.request_content"
      >
        <el-input
          type="textarea"
          v-model="sessionClass.request_content"
          placeholder="給老師的詳細介紹，如：“The student has never taken SAT classes before. He scored 1380 (580 reading, 800 math) on our mock test, so he only needs to work on the English section. He said can spend at least 1 hour on studying, so you should assign him more homework.”"
          required
        ></el-input>
      </el-form-item>
      <el-form-item
        label="可上課時間+時長"
        :rules="[commonRules.input]"
        :prop="sessionClass.request_time_info"
      >
        <el-input
          type="textarea"
          v-model="sessionClass.request_time_info"
          placeholder="如：“He’s based in Taiwan, and would like to have one class a week and 2 hours per class. He wishes to have classes on Saturday or Sunday mornings sometime between 9am-12pm Taiwan time).”"
          required
        ></el-input>
      </el-form-item>

      <el-form-item label="">
        <label>
          選擇邀請老師
          <el-tooltip content="編輯老師邀請權限" placement="top">
            <router-link :to="{ name: 'TeacherList' }" target="_blank">
              <el-button type="success" size="mini">
                <i class="fa fa-edit"></i>
              </el-button>
            </router-link>
          </el-tooltip>
        </label>
        <div style="overflow: auto;">
          <el-table
            border
            ref="multipleTable"
            :data="teachers"
            tooltip-effect="dark"
            style="width: 100%"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="60" />
            <el-table-column label="Name" width="120">
              <template slot-scope="scope">
                <router-link
                  :to="{
                    name: 'ProfileForAdmin',
                    params: { id: scope.row.id }
                  }"
                >
                  {{
                    userMethods.displayName(
                      scope.row.first_name,
                      scope.row.last_name,
                      scope.row.chinese_name
                    )
                  }}
                </router-link>
              </template>
            </el-table-column>
            <el-table-column label="Reply" width="100">
              <template slot-scope="scope">
                <el-tooltip
                  content="No Reply"
                  placement="top"
                  popper-class="tooltipColor"
                >
                  <span
                    v-show="getTeacherReplied(scope.row.id) == 0"
                    style="color:grey;"
                  >
                    <i class="el-icon-question" />
                  </span>
                </el-tooltip>
                <el-tooltip
                  content="Accepted"
                  placement="top"
                  popper-class="tooltipColor"
                >
                  <span
                    v-show="getTeacherReplied(scope.row.id) == 1"
                    style="color:forestgreen;"
                  >
                    <i class="el-icon-success" />
                  </span>
                </el-tooltip>
                <el-tooltip
                  content="Rejected"
                  placement="top"
                  popper-class="tooltipColor"
                >
                  <span
                    v-show="getTeacherReplied(scope.row.id) == 2"
                    style="color:indianred;"
                  >
                    <i class="el-icon-error"></i>
                  </span>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column width="100" label="Schools">
              <template slot-scope="scope">
                {{ helper.displayI18nText($i18n.locale, scope.row.college) }}
              </template>
            </el-table-column>
            <el-table-column  width="100" label="主修科目">
              <template slot-scope="scope">
                {{
                  helper.displayI18nText($i18n.locale, scope.row.college_major)
                }}
              </template>
            </el-table-column>
            <el-table-column width="100" :label="$t('teacherDetail.gradeLevel')">
              <template slot-scope="scope">
                {{ getTeacherGrade(scope.row.college_graduation_year) }}
              </template>
            </el-table-column>
            <el-table-column label="Subjects" width="250">
              <template slot-scope="scope">
                <el-tag
                  v-for="subject in getLabelOfSubjects(
                    JSON.parse(scope.row.subjects)
                  )"
                  :key="subject"
                  type="success"
                  class="ivy-tag"
                  size="mini"
                >
                  {{ subject }}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column label="Notes" width="150">
              <template slot-scope="scope">
                {{ scope.row.notes }}
              </template>
            </el-table-column>
            <el-table-column
              prop="hours_available"
              label="Hour Available"
              width="100"
            >
            </el-table-column>
            <el-table-column>
              <template slot="header">
                Salary/hr
              </template>
              <template slot-scope="scope">
                <b>
                  Online
                  <span v-if="sessionClass.is_online" style="color: orange">
                    （主要薪水）
                  </span>
                </b>
                <div
                  :class="`${sessionClass.is_online ? 'mainPrice' : ''}`"
                  style="margin-bottom: 10px;"
                >
                  <InputMoney
                    :money="scope.row.online_salary || 0"
                    :currency="scope.row.online_salary_currency"
                    @changeMoney="
                      online_salary => {
                        setInvitationTeacherSalary(scope.row.id, {
                          online_salary
                        });
                      }
                    "
                    @changeCurrency="
                      online_salary_currency => {
                        setInvitationTeacherSalary(scope.row.id, {
                          online_salary_currency
                        });
                      }
                    "
                  />
                </div>
                <b>
                  In-person
                  <span v-if="!sessionClass.is_online" style="color: orange">
                    （主要薪水）
                  </span>
                </b>
                <div
                  :class="`${!sessionClass.is_online ? 'mainPrice' : ''}`"
                  style="margin-bottom: 10px;"
                >
                  <InputMoney
                    :money="scope.row.salary || 0"
                    :currency="scope.row.currency"
                    @changeMoney="
                      salary => {
                        setInvitationTeacherSalary(scope.row.id, { salary });
                      }
                    "
                    @changeCurrency="
                      currency => {
                        setInvitationTeacherSalary(scope.row.id, { currency });
                      }
                    "
                  />
                </div>
                <el-checkbox
                  v-model="scope.row.need_update_salary"
                  label="更新老師薪水"
                  border
                >
                </el-checkbox>
                <el-tooltip
                  content="勾選時會更新老師的所有薪水"
                  placement="top"
                  popper-class="tooltipColor"
                >
                  <span class="action-icon">
                    <i class="fas fa-question-circle" />
                  </span>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="() => (sendInfoDialogVisible = true)">
          發送上課邀請
        </el-button>
      </el-form-item>
    </el-form>

    <el-divider></el-divider>
  </div>
</template>

<script>
import { helper, user, instant } from "@ivy-way/material";
import Breadcrumb from "@/components/Breadcrumb";
import formMixin from "@/mixins/form";
import moneyMixin from "@/mixins/money";
import profileTest from "@/mixins/profileTest";
import privateApi from "@/apis/private";
import profileApi from "@/apis/profile";
import arrangeApi from "@/apis/arrange";
import { InputMoney } from "@/components/input/";

export default {
  metaInfo() {
    return {
      title: `${this.$t("pageTitle.privateLessonInvitation")} - Ivy-Way Academy`
    };
  },
  components: {
    Breadcrumb,
    InputMoney
  },
  mixins: [formMixin, profileTest, moneyMixin],
  props: [],
  data() {
    return {
      studentClassTickets: [],
      sessionClass: {},
      courseSession: {},
      classTeacherInvitations: [],
      teachers: [],
      invitationTeachersReRender: false,
      selectedTeachers: [],
      multipleSelection: [],
      sendInfoDialogVisible: false
    };
  },
  computed: {
    helper() {
      return helper;
    },
    userMethods() {
      return user;
    },
    instant() {
      return instant;
    },
    invitedAndNeedToUpdateSalaryTeachers() {
      const teachers = [...this.needToUpdateSalaryTeachers];
      this.invitedTeachers.forEach(invitedTeacher => {
        const targetIndex = teachers.findIndex(
          teacher => teacher.id === invitedTeacher.id
        );
        if (targetIndex === -1) {
          teachers.push({
            ...invitedTeacher,
            invitedTeacher: true
          });
        } else {
          teachers[targetIndex] = {
            ...teachers[targetIndex],
            invitedTeacher: true
          };
        }
      });
      return teachers.sort((a, b) => (a.first_name > b.first_name ? 1 : -1));
    },
    invitedTeachers() {
      return this.multipleSelection.map(teacher => ({
        ...teacher,
        currency: teacher.currency ? teacher.currency : "TWD",
        online_salary_currency: teacher.online_salary_currency || "TWD"
      }));
    },
    needToUpdateSalaryTeachers() {
      return this.teachers
        .filter(({ need_update_salary }) => need_update_salary)
        .map(teacher => ({
          ...teacher,
          currency: teacher.currency ? teacher.currency : "TWD",
          online_salary_currency: teacher.online_salary_currency || "TWD",
          needToUpdateSalaryTeacher: true
        }));
    }
  },
  watch: {},

  async mounted() {
    await this.loadStudentClassTicket();
    this.loadTeachers();
  },

  methods: {
    async loadStudentClassTicket() {
      let {
        session_class,
        student_class_tickets
      } = await privateApi.getCustomLesson({
        class_id: this.$route.params.id
      });

      this.studentClassTickets = student_class_tickets;
      this.sessionClass = session_class;
      this.courseSession = session_class.course_session;

      this.classTeacherInvitations = session_class.class_teacher_invitations;
    },
    async loadTeachers() {
      let teachersRes = await profileApi.fetchTeachers({
        with_show_on_invitation_list: 1
      });
      this.teachers = teachersRes.users.map(teacher => ({
        ...teacher,
        salary: teacher.in_person_salary || 0,
        currency: teacher.in_person_currency || "TWD",
        online_salary: teacher.online_salary || 0,
        online_salary_currency: teacher.online_currency || "TWD",
        need_update_salary: false
      }));
    },
    sendInvitationToTeacherAndUpdateSalary() {
      this.updateTeacherSalary();
      this.sendInvitationToTeacher();
    },
    updateTeacherSalary() {
      const updateTeacherSalaryPromises = this.teachers
        .filter(teacher => teacher.need_update_salary)
        .map(teacher =>
          this.$store.dispatch("profile/updateTeacherProfileByAdmin", {
            id: teacher.id,
            newProfile: {
              in_person_salary: teacher.salary,
              in_person_currency: teacher.currency,
              online_salary: teacher.online_salary,
              online_currency: teacher.online_salary_currency
            }
          })
        );
      Promise.all(updateTeacherSalaryPromises);
    },
    async sendInvitationToTeacher() {
      let params = {
        class_id: this.$route.params.id,
        teachers: this.invitedTeachers,
        request_content: this.sessionClass.request_content,
        request_time_info: this.sessionClass.request_time_info
      };

      await arrangeApi.inviteTeachers(params);
      this.$message({
        showClose: true,
        message: this.$t("message.update_success"),
        type: "success"
      });
      this.routerPush({
        name: "privateClassesDetail",
        params: {
          id: this.$route.params.id
        }
      }).catch(error => {
        console.log(error);
        this.$message({
          showClose: true,
          message: this.$t("message.something_went_wrong"),
          type: "error"
        });
      });
      this.sendInfoDialogVisible = false;
    },
    setSelectionAfterReRenderTeachers() {
      if (this.invitationTeachersReRender) {
        this.invitationTeachersReRender = false;
        this.selectedTeachers.forEach(selectedTeacher => {
          this.$refs.multipleTable.toggleRowSelection(selectedTeacher);
        });
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.setSelectionAfterReRenderTeachers();
    },
    setInvitationTeacherSalary(id, value) {
      const targetTeacherIndex = this.teachers.findIndex(
        teacher => teacher.id === id
      );
      this.teachers[targetTeacherIndex] = {
        ...this.teachers[targetTeacherIndex],
        ...value
      };
      this.teachers = [...this.teachers];

      this.invitationTeachersReRender = true;
      this.selectedTeachers = this.teachers.filter(teacher =>
        this.multipleSelection.find(
          selectedTeacher => selectedTeacher.id === teacher.id
        )
      );
    },
    getTeacherReplied(teacherId) {
      const teacherRepliedStatus = this.classTeacherInvitations.find(
        classTeacherInvitation =>
          classTeacherInvitation.teacher_user_id === teacherId
      );
      if (!teacherRepliedStatus) return null;
      return teacherRepliedStatus.replied_status;
    }
  }
};
</script>

<style scoped>
.el-select .el-input {
  width: 130px;
}
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}
.ivyway-green {
  color: #42a16a;
}
.container-fluid {
  padding: 20px;
  max-width: 95vw;
}
/* .mainPrice {
  border: 2px solid orange;
  padding: 4px;
  box-sizing: border-box;
  border-radius: 4px;
  width: max-content;
} */

.checkIcon {
  position: relative;
  animation: fadeIn 1s;
}
@keyframes fadeIn {
  0% {
    bottom: 10px;
    opacity: 0;
  }
  100% {
    bottom: 0px;
    opacity: 1;
  }
}
</style>
